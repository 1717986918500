<template>
  <div class="dashboard">
    <Filters
      :on-change="onChangeFilters"
      :loading="loading"
      @filterError="onErrorFilters"
    />
    <DataStats :data="dataStats" />
  </div>
</template>

<script>
  import ElasticAPI from '../../services/API/ElasticAPI';
  import fetchFilters from "../../mixins/fetchFilters";

  export default {
    name: 'dashboard',
    mixins: [fetchFilters],

    components: {
      DataStats: ()=> import('../stats/DataStats'),
      Filters: ()=> import('../filters/Filters'),
    },

    data() {
      return {
        dataStats: [],
      };
    },

    async created() {
      if (!this.user.roles.includes('ROLE_ADMIN')) {
        this.user.retailers.forEach(retailer => {
          this.filters.retailers.push(retailer.id);
        });
        this.user.brands.forEach(brand => {
          this.filters.brands.push(brand.id);
        });
      }

      this.dataStats = await ElasticAPI.getDataStats(this.filters);
      this.loading = false;
    },

    methods: {
      async refreshData() {
        const promiseDataStats = ElasticAPI.getDataStats(this.filters);

        await promiseDataStats.then(data => {
          this.dataStats = data;
        });
      },
    },

  };
</script>

<style lang="scss">
  .row-equal .flex {
    .vac-card {
      height: 100%;
    }
  }

  .dashboard {
    .vac-card {
      margin-bottom: 0 !important;
    }
  }

  .link-icon {
    color: #000000;
    transition: 0.3s;
    cursor: pointer;
    margin-left: 26px;
  }

  .link-icon:hover {
    opacity: 0.7;
  }

  .td--small {
    width: 20%;
  }

  .dashboard-contributors-list {
    flex-direction: column;
    height: 100%;
  }

  .inner-loading {
    table {
      width: 100%;
    }

    .va-table td {
      word-break: break-all;
    }

    tbody,
    thead {
      width: 100%;
    }
  }

</style>
